.notification {
  position: fixed;
  z-index: 1000000;
  /* top: 1rem;
  right: 5%; */
  max-width: 90%;

  background-color: var(--text-primary-color);
  color: #fff;

  border-radius: 3px;
  padding: 1rem;

  // mobile default
  top: 0rem;
  right: 50%;
  transform: translateX(50%);
  animation: fadeTop 1s;
  background-color: red;

  .closeButton {
    position: absolute;
    right: 0.25rem;
    top: 0.25rem;
    background: transparent;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 1.25rem;
    cursor: pointer;
  }

  h4 {
    margin: 0;
  }

  > span {
    font-weight: 700;
    font-size: 0.875rem;
  }
}

@-webkit-keyframes fadeTop {
  0% {
    top: 100%;
    opacity: 0;
  }
  100% {
    top: -100%;
    opacity: 1;
  }
}

@-moz-keyframes fadeTop {
  0% {
    top: -100%;
    opacity: 0;
  }
  100% {
    top: 0rem;
    opacity: 1;
  }
}

@keyframes fadeTop {
  0% {
    top: -100%;
    opacity: 0;
  }
  100% {
    top: 0rem;
    opacity: 1;
  }
}

@-webkit-keyframes fadeBottom {
  0% {
    bottom: 100%;
    opacity: 0;
  }
  100% {
    bottom: -100%;
    opacity: 1;
  }
}

@-moz-keyframes fadeBottom {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 0rem;
    opacity: 1;
  }
}

@keyframes fadeBottom {
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: 0rem;
    opacity: 1;
  }
}
