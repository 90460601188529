@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.deleteBg {
  top: 0;
  width: 100vw;
  left: 0;
  height: 100vh;
  background-color: rgba(219, 219, 219, 0.8);
  position: fixed;
  z-index: 5;
  animation-duration: 0.5s;
  animation-name: fade-in;
}

.deleteWrapper {
  position: fixed;
  padding: 2rem 1rem;
  background-color: white;
  border-radius: 0.25rem;
  top: 50%;
  left: 50%;
  width: 40%;
  height: fit-content;
  transform: translate(-50%, -50%);
  overflow: inherit;
}

.deleteHeader {
  text-align: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteButton {
  background-color: red;
  color: var(--background-color);
  border-radius: 0.125rem;
  width: fit-content;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border: none;
  margin-right: 0.25rem;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.CancelButton {
  background-color: gray;
  color: var(--background-color);
  border-radius: 0.125rem;
  cursor: pointer;
  width: fit-content;
  padding: 0.5rem 1.5rem;
  border: none;
  margin-right: 0.25rem;
}
