@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.AddMachinePopUpBg {
  top: 0;
  width: 100vw;
  left: 0;
  height: 100vh;
  background-color: rgba(219, 219, 219, 0.8);
  position: fixed;
  z-index: 5;
  animation-duration: 1s;
  animation-name: fade-in;
}

.AddMachinePopUpWrapper {
  position: fixed;
  background-color: white;
  border-radius: 0.25rem;
  top: 50%;
  left: 50%;
  width: 60%;
  height: fit-content;
  transform: translate(-50%, -50%);
}

.AddPopUpHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  background-color: var(--primary-color);
  height: 3rem;
  color: var(--background-color);
}

.AddPopUpContent {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.MachineInput {
  margin: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: solid 1px var(--background-color);
  background-color: var(--background-color);
}
.CustomerInput:hover {
  border: solid 1px gray;
}

.buttonWrapper {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addBtn {
  background-color: var(--primary-color);
  color: var(--background-color);
  border-radius: 0.125rem;
  width: fit-content;
  padding: 0.5rem;
  border: none;
  margin-right: 0.25rem;
}

.cancelBtn {
  background-color: gray;
  color: var(--background-color);
  border-radius: 0.125rem;
  width: fit-content;
  padding: 0.5rem;
  cursor: pointer;
  border: none;
  margin-right: 0.25rem;
}
