@keyframes hover_color_change {
  from {
    color: var(--background-color);
    background-color: var(--primary-color);
  }
  to {
    background-color: var(--background-color);
    color: var(--primary-color);
  }
}

.NavbarWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 9%;
  height: 100%;
  z-index: 55;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  box-shadow: 5px 2px 3px 0px rgba(0, 0, 0, 0.3);

  img {
    margin-top: 2rem;
    width: 7rem;
    height: 5rem;
  }
}

.navbarHeader {
  color: var(--background-color);
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.navbarItem {
  color: var(--background-color);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 1rem 0.75rem;
  margin-top: 0.25rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  &:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    border-radius: 0.25rem 0.25rem 0 0;
    animation-name: hover_color_change;
    animation-duration: 1s;
  }
}

.navbarItemsWrapper {
  height: 70%;
  margin-left: 1rem;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.navbarUserName {
  color: var(--background-color);
  font-size: 1rem;
  font-weight: 500;
}

.logOut {
  color: var(--background-color);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0.75rem;
  margin-top: 0.25rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  &:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    border-radius: 0.25rem;
    animation-name: hover_color_change;
    animation-duration: 1s;
    width: fit-content;
    svg {
      fill: var((--primary-color));
    }
  }
  svg {
    margin-right: 0.25rem;
    fill: var(--background-color);
    width: 1.5rem;
    height: 1.5rem;
  }
}
