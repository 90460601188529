.logInBg {
  background-color: rgba(13, 61, 171, 0.2);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logInWrapper {
  width: 40%;
  padding: 1rem;
  border-radius: 0.125rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 15rem;
  background-color: #f5f5f5;
  box-shadow: 0px 5px 6px 0px rgba(13, 71, 161, 0.3);
}

.logInItem {
  text-align: center;
  padding: 1rem;
  width: 90%;
}

.logInHeader {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
}

@keyframes hoverLoginInput {
  from {
    padding: 0.25rem;
  }
  to {
    padding: 0.75rem;
  }
}
@keyframes offhoverLoginInput {
  from {
    padding: 0.75rem;
  }
  to {
    padding: 0.25rem;
  }
}
.LoaderWrapper {
  top: 0;
  width: 100vw;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(219, 219, 219, 0.8);
  position: absolute;
  z-index: 5;
}
.logInInput {
  background-color: #f5f5f5;
  border-bottom: 2px solid #dbdbdb;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 60%;
  margin-bottom: 0.5rem;
  animation-name: offhoverLoginInput;
  animation-duration: 0.25s;
  &:hover {
    animation-name: hoverLoginInput;
    animation-duration: 0.25s;
    padding: 0.75rem;
  }
}

.addBtn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
