.machinesWrapper {
  padding: 1rem;
}

/* .machineItemBg {
  padding: 1rem;
}

.swiperWrapper {
  display: flex;
  align-items: center;
}

.machineItem {
  display: flex;
  border: solid 1px var(--primary-color);
  width: fit-content;

  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999%;
  }

  input[type="checkbox"] + label {
    cursor: pointer;
  }

  input[type="checkbox"]:checked + label {
    box-shadow: 0px 5px 6px 0px rgba(13, 71, 161, 0.3);
    border: solid 1px white;
    background-color: var(--primary-color);
    color: var(--primary-color);
  }

  label {
    display: flex;
  }

  &:hover {
    box-shadow: 0px 5px 6px 0px rgba(13, 71, 161, 0.3);
  }
}

.machineField {
  border: solid 1px rgba(13, 71, 161, 0.4);
  border-radius: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin: 0.5rem;
  background-color: white;
  padding: 0.25rem 0.75rem;
  width: fit-content;
} */

.machineBtnWrapper {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.machineAddBtn {
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.125rem;
  color: var(--background-color);
  background-color: var(--primary-color);
  margin-right: 0.5rem;
  cursor: pointer;
}

.machineDeleteBtn {
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.125rem;
  color: var(--background-color);
  background-color: red;
  cursor: pointer;
}
