@font-face {
  font-family: "New Courier";
  src: url("./Courier\ Prime.ttf");
}

/* .workOrdersWrapper {
  font-family: "New Courier";
} */

.customerDetailsHeaderWrapper {
  margin-left: 2rem;
}

.orderPartButtonsWrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  width: 100%;

  button {
    padding: 1em 2em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--primary-color);
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(13, 71, 161, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }

  button:hover {
    background-color: var(--secondary-color);
    box-shadow: 0px 15px 20px rgba(243, 120, 38, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }

  button:active {
    transform: translateY(-1px);
  }
}

.orderPartFiltersWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin-left: 2rem;

  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999%;
  }

  input[type="checkbox"] + label {
    border: 1px solid var(--primary-color);
    padding: 0.25rem 0.75rem;
    margin-left: 1rem;
    text-align: center;
    border-radius: 0.5rem;
    color: var(--primary-color);
    cursor: pointer;
  }
  @keyframes checkboxChecked {
    from {
      background-color: var(--background-color);
      color: var(--primary-color);
    }
    to {
      background-color: var(--primary-color);
      color: var(--background-color);
    }
  }
  input[type="checkbox"]:checked + label {
    background-color: var(--primary-color);
    color: var(--background-color);
    animation-name: checkboxChecked;
    animation-duration: 0.5s;
  }
}

.customerDetailsHeader {
  font-size: 1.5rem;
  font-weight: 600;
}

.customerDetailsId {
  font-weight: 600;
}

.checkId {
  position: absolute;
  left: 2rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
}

@keyframes hoverBGChanger {
  from {
    background-color: var(--background-color);
    color: black;
  }
  to {
    background-color: rgba(255, 0, 0, 0.4);
    color: #f5f5f5;
  }
}

.OrderItem:hover {
  animation-name: hoverBGChanger;
  animation-duration: 0.5s;
  background-color: rgba(255, 0, 0, 0.4);
  color: #f5f5f5;
}

.orderCardsWrapper {
  margin-top: 1rem;
}

.addBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
