.calculationDetailsPageWrapper {
  .LoaderWrapper {
    top: 0;
    width: 100vw;
    left: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(219, 219, 219, 0.8);
    position: absolute;
    z-index: 5555;
  }
  .backIcon {
    position: absolute;
    margin: 1rem;
    cursor: pointer;
  }

  .topPartWrapper {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;

    .firmsWrapper {
      width: 40%;
      margin-left: 2rem;
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 12.5rem;

      button {
        background-color: var(--primary-color);
        color: var(--background-color);
        border: 1px solid #fff;
        cursor: pointer;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        font-weight: 600;
        font-size: 1rem;
        z-index: 55;
        display: block;
        margin: 1rem auto 0;
      }
      .firmsWrapperHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
      }
      .firmsWrapperBody {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
        flex: 1;
        min-height: 0;

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .firmsWrapperBodyItem {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          input {
            width: 100%;
          }
        }
      }
    }

    .navlunOrdinoWrapper {
      display: flex;
      height: 10rem;
      width: 15%;
      padding: 1rem;
      flex-direction: column;
      align-items: center;
      border-left: 2px double var(--secondary-color);
      .HeaderCifTotal {
        border-bottom: 2px dashed var(--secondary-color);
        width: 60%;
        text-align: center;
        margin-bottom: 0.5rem;
        font-weight: 500;
      }

      .cifTotal {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: 3px dotted var(--secondary-color);
        width: 80%;
        font-weight: 500;
        text-align: center;
      }
      .navlunOrdinoItem {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 0.5rem;

        input {
          margin-top: 0;
          width: 50%;

          &:disabled {
            background-color: gray;
            opacity: 0.7;
            cursor: not-allowed;
          }
        }

        input[type="checkbox"] + label {
          width: 3rem;
          padding: 0.25 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        h4 {
          margin: 0;
        }
      }
    }

    .otherExpensesTop {
      width: 20%;
      height: 10rem;
      padding: 1rem;
      border-left: 2px double var(--secondary-color);
      z-index: 55;

      .otherExpensesWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
      }
      .otherExpensesValueOther {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        div {
          text-align: center;
          width: 80%;
          font-weight: 500;
        }
      }
    }
  }

  .manuelCalcGeneral {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;

    .manuelCalcWrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;

      input {
        width: 60% !important;
      }
    }
  }

  .accordionExpensesCalculationWrapper {
    height: 3rem;
    margin-top: 3.5rem;
    z-index: 5;

    .accordionExpenses {
      .accordionExpensesHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-weight: 500;
        font-size: 1.125rem;
      }

      .otherExpensesWrapper {
        border-top: 1px solid var(--secondary-color);
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--primary-color);
        gap: 1rem;

        .otherExpensesValueOther {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70%;
          div {
            text-align: center;
            width: 80%;
            font-weight: 500;
          }
        }
        .otherExpensesHeader {
          font-weight: 500;
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 2px dashed var(--secondary-color);
          padding-bottom: 0.25rem;
        }

        input {
          margin-top: 0.5rem;
          width: 40%;
        }
        .otherExpensesValueManuel {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }
  .otherExpensesTotal {
    text-align: center;
    width: 50%;
    border-top: 2px dashed var(--secondary-color);
    font-weight: 600;
    padding-top: 1rem;
    padding-bottom: 1rem;

    border-bottom: 2px dashed var(--secondary-color);
  }

  .accordionList {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem 0;

    h2 {
      border-bottom: 2px dashed var(--secondary-color);
      width: 25%;
    }

    .accordionWrapper {
      width: 90%;
      margin-top: 1rem;
      background-color: var(--primary-color);
      color: #f5f5f5;

      .accordionHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-weight: 400;
        font-size: 1.125rem;
      }

      .totalValuesAccordionWrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;

        .totalValues {
          width: 50%;
        }
      }
    }
  }

  .sendStockButton {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--secondary-color);
    color: #f5f5f5;
    font-size: 0.875rem;
    border: 1px solid transparent;
    border-radius: 0.125rem;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  /*   @keyframes hoverLoginInput {
    from {
      padding: 0.25rem;
    }
    to {
      padding: 0.5rem;
    }
  }
  @keyframes offhoverLoginInput {
    from {
      padding: 0.5rem;
    }
    to {
      padding: 0.25rem;
    }
  } */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input {
    background-color: var(--primary-color);
    cursor: pointer;
    color: var(--background-color);
    border-bottom: 2px solid var(--secondary-color);
    border-radius: 0.125rem 0.125rem 0 0;
    border-top: none;
    border-left: none;
    border-right: none;
    width: 60%;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0.5rem;
    animation-name: offhoverLoginInput;
    animation-duration: 0.25s;

    &:not([value=""]):not([value="0"]) {
      background-color: var(--secondary-color);
    }

    &[type="number"] {
      &:not(:placeholder-shown) {
        background-color: var(--primary-color);
      }
      &:not([value="0"]):not([value="0.0"]):not([value="0.00"]):not([value=""]) {
        background-color: var(--secondary-color);
      }
    }
  }

  ::placeholder {
    color: var(--background-color) !important;
  }

  .header {
    width: 80%;
    margin-left: 10%;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    padding: 1.5rem;
    color: var(--primary-color);
    border-bottom: 2px solid var(--secondary-color);
  }
  .calculationsButtonWrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    align-items: center;
    top: 0;
    right: 8rem;
    gap: 2rem;
    margin-top: 2rem;

    button {
      padding: 0.5rem 1rem;
      color: var(--background-color);
      font-size: 1rem;
      border-radius: 0.25rem;
      font-weight: 600;
      text-align: center;
      border: 1px solid #fff;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      background-color: var(--primary-color);
    }
    .calculationCancelButton {
      background-color: var(--secondary-color);
      border: 1px solid #fff;
    }
  }

  .doviz {
    position: absolute;
    top: -1rem;
    right: 2rem;
  }

  .mainMenuWrapper {
    border: 2px solid var(--secondary-color);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding-bottom: 1rem;
  }
  .totalValuesWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }

  .totalValues {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .calculationProductInput {
      background-color: var(--background-color);
      color: var(--primary-color);
    }

    input {
      color: var(--background-color);

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    h4 {
      border-bottom: 2px dashed var(--secondary-color);
      width: 100%;
      padding-bottom: 0.5rem;
    }

    p {
      border-top: 2px solid var(--secondary-color);
      border-right: 1px solid var(--secondary-color);

      padding-top: 0.75rem;
      width: 75%;
    }

    .orderPartFiltersWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }
  }
  h3 {
    border-bottom: 2px dashed var(--secondary-color);
    width: 70%;
    text-align: center;
  }
  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999%;
  }

  input[type="checkbox"] + label {
    border: 1px solid var(--primary-color);
    padding: 0.25rem 0.75rem;
    margin-left: 1rem;
    text-align: center;
    border-radius: 0.5rem;
    color: var(--primary-color);
    cursor: pointer;
  }
  @keyframes checkboxChecked {
    from {
      background-color: var(--background-color);
      color: var(--primary-color);
    }
    to {
      background-color: var(--primary-color);
      color: var(--background-color);
    }
  }
  input[type="checkbox"]:checked + label {
    background-color: var(--primary-color);
    color: var(--background-color);
    animation-name: checkboxChecked;
    animation-duration: 0.5s;
  }
  .nameNoteWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    textarea {
      max-width: 15rem;
      max-height: 5rem;
      min-width: 10rem;
      min-height: 2rem;
      background-color: var(--primary-color);
      color: var(--background-color);
      border-bottom: 2px solid var(--secondary-color);
    }

    input {
      width: 80%;
    }
  }
  .rSelectWrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;

    flex-direction: column;

    .rSelect {
      z-index: 55;
      width: 100%;
    }
    h3 {
      width: 40%;
      border-bottom: 2px dashed var(--secondary-color);
    }
  }
  .calcsTopWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    gap: 2rem;
    margin-top: 1rem;
  }

  .oilsValuesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
  }

  .currencyValuesWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin: 0.5rem auto 0;
    width: 60%;

    .currencyRow {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.3rem 0.5rem;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 10%;
        right: 10%;
        border-top: 1px solid var(--secondary-color);
      }

      &.euro {
        background-color: var(--primary-color);
        color: var(--background-color);
      }

      &.lira {
        color: var(--secondary-color);
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 10%;
          right: 10%;
          border-bottom: 1px solid var(--secondary-color);
        }
      }

      span:first-child {
        font-size: 1.1rem;
        font-weight: 600;
        margin-right: 0.2rem;
      }

      span:last-child {
        font-size: 1.1rem;
        font-weight: 600;
      }
    }
  }
}
