.table-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 70px 70px;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
}

.stockCardIconsWrapper {
  border: none;
  background-color: inherit;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.stockCardHeader {
  width: 40%;
  margin-left: 30%;
  text-align: center;
  color: var(--primary-color);
  padding-bottom: 0.5rem;
  border-bottom: 4px dashed var(--secondary-color);
}

.fl-table {
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.fl-table td,
.fl-table th {
  text-align: center;
  padding: 8px;
}

.fl-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 1rem;
}

.fl-table thead th {
  color: #ffffff;
  background: var(--secondary-color);
}

.fl-table thead th:nth-child(odd) {
  color: #ffffff;
  background: #324960;
}

.fl-table tr:nth-child(even) {
  background: #f8f8f8;
}

/* Responsive */

@media (max-width: 767px) {
  .fl-table {
    display: block;
    width: 100%;
  }
  .table-wrapper:before {
    content: "Scroll horizontally >";
    display: block;
    text-align: right;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }
  .fl-table thead,
  .fl-table tbody,
  .fl-table thead th {
    display: block;
  }
  .fl-table thead th:last-child {
    border-bottom: none;
  }
  .fl-table thead {
    float: left;
  }
  .fl-table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }
  .fl-table td,
  .fl-table th {
    padding: 20px 0.625em 0.625em 0.625em;
    height: 60px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 120px;
    font-size: 13px;
    text-overflow: ellipsis;
  }
  .fl-table thead th {
    text-align: left;
    border-bottom: 1px solid #f7f7f9;
  }
  .fl-table tbody tr {
    display: table-cell;
  }
  .fl-table tbody tr:nth-child(odd) {
    background: none;
  }
  .fl-table tr:nth-child(even) {
    background: transparent;
  }
  .fl-table tr td:nth-child(odd) {
    background: #f8f8f8;
    border-right: 1px solid #e6e4e4;
  }
  .fl-table tr td:nth-child(even) {
    border-right: 1px solid #e6e4e4;
  }
  .fl-table tbody td {
    display: block;
    text-align: center;
  }
}

.productCardInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  gap: 3rem;
  margin-left: 5%;
}

.productCardInfo {
  width: 15%;
  text-align: center;
  color: var(--primary-color);
  h3 {
    padding-bottom: 0.25rem;
    border-bottom: 2px dashed var(--secondary-color);
  }
}

.productNote {
  border-bottom: 2px solid var(--secondary-color);
  border-left: 1px solid var(--secondary-color);
  border-right: 1px solid var(--secondary-color);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--primary-color);
  background-color: #ffffff;
  border-radius: 0.25rem;
  padding: 0.75rem 0.25rem;
}

.stockCardWarningWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
