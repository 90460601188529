.StockWrapper {
  margin-left: 12rem;

  .stockHeaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .stockHeader {
      width: 95%;
      text-align: center;
      color: var(--primary-color);
      padding-bottom: 1rem;
      border-bottom: 3px solid rgba($color: #dbdbdb, $alpha: 0.5);
    }
  }
  .LoaderStockWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
  }

  .stockInformationWrapper {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 3px solid rgba($color: #dbdbdb, $alpha: 0.5);

    .stockDropdownWrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .stockInfoButtonWrapper {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
      .productDropdown {
        width: 60%;
      }
    }
    .stockInfoItemWrapper {
      width: 60%;
      display: flex;

      .stockInfoItem {
        width: fit-content;
        height: fit-content;
        padding: 0.5rem;
        text-align: center;
        margin-top: 1rem;
        margin-left: 2rem;
        border: solid 1px;
        color: var(--primary-color);
        border-radius: 0.125rem;
      }
    }
  }
}
