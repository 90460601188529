.AddPopUpPaymentEntryWrapper {
  position: fixed;
  background-color: white;
  border-radius: 0.25rem;
  top: 50%;
  left: 50%;
  height: 40vh;
  width: 60%;
  transform: translate(-50%, -50%);
}
