@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* @keyframes SearchBarMoveOut {
  from {
    top: 17.5rem;
  }
  to {
    top: 5rem;
  }
}
 */
.CustomerWrapper {
  width: 90%;
  margin-left: 7rem;
  padding: 1rem;
  padding-top: 0;

  /*   display: flex;
  align-items: center; */

  .homeHeaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .homeHeader {
      width: 95%;
      text-align: center;
      color: var(--primary-color);
      padding-bottom: 1rem;
      border-bottom: 3px solid rgba($color: #dbdbdb, $alpha: 0.5);
    }
  }

  .homeCurrencyWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--primary-color);
    span {
      border-bottom: 2px dashed var(--secondary-color);
      padding-bottom: 0.125rem;
    }
  }

  .dropdownButtonWrapper {
    width: 95%;
    margin-top: 1.25rem;
    .dpBtnWrppr {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }
    .customerBtnWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.25rem;

      .cssbuttons_io_button {
        background: var(--primary-color);
        color: white;
        font-family: inherit;
        padding: 0.35em;
        padding-left: 1.2em;
        font-size: 17px;
        font-weight: 500;
        border-radius: 0.25em;
        border: none;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        box-shadow: inset 0 0 1.6em -0.6em var(--primary-color);
        overflow: hidden;
        position: relative;
        height: 2.8em;
        padding-right: 3.3em;
        cursor: pointer;
      }

      .cssbuttons_io_button .icon {
        background: var(--secondary-color);
        margin-left: 1em;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.2em;
        width: 2.2em;
        border-radius: 0.25em;
        box-shadow: 0.1em 0.1em 0.6em 0.2em var(--primary-color);
        right: 0.3em;
        transition: all 0.3s;
      }

      .cssbuttons_io_button:hover .icon {
        width: calc(100% - 0.6em);
      }

      .cssbuttons_io_button .icon svg {
        width: 1.1em;
        transition: transform 0.3s;
        color: var(--background-color);
      }

      .cssbuttons_io_button:hover .icon svg {
        transform: translateX(0.1em);
      }

      .cssbuttons_io_button:active .icon {
        transform: scale(0.95);
      }
    }
    .addButton {
      margin-left: 0.75rem;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.customerPart {
  width: 50%;
}

.customerDropdown {
  width: 30rem;
  padding: 1rem;
}

.addButton {
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.125rem;
  color: var(--background-color);
  background-color: var(--primary-color);
  cursor: pointer;
}

.infoAndButtonWrapper {
  animation-name: fade-in;
  animation-duration: 1s;
}

.machines {
  border-left: solid 1px #dbdbdb;
  height: max-content;
  animation-name: fade-in;
  animation-duration: 1s;
}

@keyframes hoverDefaultInput {
  from {
    padding: 0.25rem;
  }
  to {
    padding: 0.75rem;
  }
}
@keyframes offhoverDefaultInput {
  from {
    padding: 0.75rem;
  }
  to {
    padding: 0.25rem;
  }
}

.defaultWorkWrapper {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  margin: 2rem 0;
  justify-content: center;
  align-items: center;
  input {
    border-bottom: 2px solid var(--secondary-color) !important;
    border: none;
    background-color: rgba($color: var(--primary-color), $alpha: 0.4);
    padding: 0.25rem;
    text-align: center;
    animation-name: offhoverDefaultInput;
    font-size: 0.875rem;
    animation-duration: 0.25s;
    &:hover {
      animation-name: hoverDefaultInput;
      animation-duration: 0.25s;
      padding: 0.75rem;
    }
  }
  button {
    font-size: 0.875rem;
  }
}

.deleteCustomer {
  margin-left: 0.5rem;
  padding: 0.75rem 1rem;
  cursor: pointer;
  border: none;
  border-radius: 0.125rem;
  color: var(--background-color);
  background-color: rgb(187, 1, 1);
  animation-name: fade-in;
  animation-duration: 1s;
}

.LoaderWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.gitLinks {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  font-size: 0.825rem;
  color: var(--primary-color);
  a {
    color: var(--secondary-color);
  }
}
