.InfoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.responsive-table {
  li {
    border-radius: 3px;
    padding: 1.25rem 1.25rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .table-header {
    background-color: var(--primary-color);
    color: var(--background-color);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .table-row {
    background-color: #ffffff;
    font-weight: 500;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  }
  .col-1 {
    flex-basis: 40%;
  }
  .col-2 {
    flex-basis: 30%;
  }
  .col-3 {
    flex-basis: 20%;
  }
  .col-4 {
    flex-basis: 20%;
  }

  @media all and (max-width: 767px) {
    .table-header {
      display: none;
    }

    li {
      display: block;
    }
    .col {
      flex-basis: 100%;
    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: var(--primary-color);
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
}
/* .customerInfoWrapper {
   display: flex;
  justify-content: flex-start; 
}

.customerInfoItem {
  border: solid 1px rgba(13, 71, 161, 0.4);
  border-radius: 0.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  background-color: white;
  padding: 0.25rem 0.75rem;
  width: fit-content;
}
.customerInfoHeader {
  font-weight: 500;
  margin-right: 0.25rem;
}

.addressTax {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} */

.addButtonWrapper {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
