/*

RESPONSTABLE 2.0 by jordyvanraaij
  Designed mobile first!

If you like this solution, you might also want to check out the 1.0 version:
  https://gist.github.com/jordyvanraaij/9069194

*/

// Default options for table style
$table-breakpoint: 480px;
$table-background-color: #fff;
$table-text-color: var(--primary-color);
$table-outer-border: 1px solid var(--primary-color);

$table-cell-border: 1px solid var(--background-color);

// Extra options for table style (parse these arguments when including your mixin)
$table-border-radius: 0.125rem;
$table-highlight-color: #eaf3f3;
$table-header-background-color: var(--primary-color);
$table-header-text-color: #fff;
$table-header-border: 1px solid #fff;

// The Responstable mixin

@mixin responstable(
  $breakpoint: $table-breakpoint,
  $background-color: $table-background-color,
  $text-color: $table-text-color,
  $cell-border: $table-cell-border,
  $outer-border: $table-outer-border,
  $border-radius: none,
  $highlight-color: none,
  $header-background-color: $table-background-color,
  $header-text-color: $table-text-color,
  $header-border: $table-cell-border
) {
  .responstable {
    margin: 1em 0;
    width: 100%;
    overflow: hidden;
    background: $background-color;
    color: $text-color;
    border-radius: $border-radius;
    border: $outer-border;

    tr {
      border: $cell-border;

      &:nth-child(odd) {
        // highlight the odd rows with a color
        background-color: $highlight-color;
      }
    }

    th {
      display: none; // hide all the table header for mobile
      border: $header-border;
      background-color: $header-background-color;
      color: $header-text-color;

      padding: 1em;
      &:first-child {
        // show the first table header for mobile
        display: table-cell;
        text-align: center;
      }
      &:nth-child(2) {
        // show the second table header but replace the content with the data-th from the markup for mobile
        display: table-cell;
        span {
          display: none;
        }
        &:after {
          content: attr(data-th);
        }
      }
      @media (min-width: $breakpoint) {
        &:nth-child(2) {
          // hide the data-th and show the normal header for tablet and desktop
          span {
            display: block;
          }
          &:after {
            display: none;
          }
        }
      }
    }

    td {
      display: block; // display the table data as one block for mobile
      word-wrap: break-word;
      max-width: 7em;

      &:first-child {
        display: table-cell; // display the first one as a table cell (radio button) for mobile
        border-right: $cell-border;
      }
      @media (min-width: $breakpoint) {
        border: $cell-border;
      }
    }

    th,
    td {
      text-align: center;
      margin: 0.5em 1em;
      @media (min-width: $breakpoint) {
        display: table-cell; // show the table as a normal table for tablet and desktop
        padding: 1em;
      }
    }
  }
}

// Include the mixin (with extra options as overrides)

@include responstable(
  $border-radius: $table-border-radius,
  $highlight-color: $table-highlight-color,
  $header-background-color: $table-header-background-color,
  $header-text-color: $table-header-text-color,
  $header-border: $table-header-border
);

.billPartTopWrapper {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  margin-left: 1rem;
}

.billPartCustomerInfoWrapper {
  width: 40%;
  .billPartHeaderWrapper {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .billPartHeaderId {
    font-size: 1rem;
  }
}

.BillPaymentsWrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 1rem;
  justify-content: center;
  color: var(--primary-color);

  .BillsWrapper {
    width: 80%;
    text-align: center;
  }
  .PaymentsWrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--secondary-color);

    .paymentCardsHeaderWrapper {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      color: var(--secondary-color);

      text-align: center;
      align-items: center;

      .paymentCardHeader {
        font-size: 1.125rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid var(--secondary-color);
        font-weight: 700;
      }
      .paymentCardValue {
        margin-top: 1rem;
        font-size: 1.125rem;
        font-weight: 500;
      }
    }
  }
}

.billPartInfo {
  background-color: white;
  border: 1px dashed var(--primary-color);
  border-radius: 0.125rem;
  padding: 0.5rem;
  display: flex;
  font-weight: 500;
  font-size: 1.125rem;
  justify-content: space-around;
  width: 70%;
}

.billInfoHeader {
  border-bottom: solid 1px var(--primary-color);
  font-weight: 500;
  text-align: center;
}
.billInfoValue {
  text-align: center;
}
.billPartInputBtnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  .billPartInputWrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-right: 2rem;

    .billPartInputHeader {
      padding-bottom: 0.5rem;
      color: var(--primary-color);
      font-size: 1.125rem;
      font-weight: 500;
      border-bottom: 2px solid var(--primary-color);
    }

    input {
      margin-top: 0.5rem;
      border: 1px dashed var(--primary-color);
      padding: 0.375rem;
    }
  }

  .addBtn {
    margin-top: 1rem;
  }
}

.pdfWrapper {
  width: 100%;
  display: flex;
  color: black;
  flex-direction: column;
  font-family: "New Courier";
  align-items: center;
  justify-content: center;
  font-size: 0.575rem;
}

.pdfInfoWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.pdfBilltableWrapper {
  width: 95%;
  padding-right: 0.5rem;
  height: 842px;
}

.pdfBillcustomerDetailsHeaderWrapper {
  margin-left: 1rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  position: absolute;
  .billcustomerDetailsHeader {
    width: 50%;
    font-size: 1.5rem;
    font-weight: 600;
  }
}

.pdfBillInfoLeftItemsWrapper {
  margin-top: 5rem;
  display: flex;
  width: 7rem;
  text-align: center;
  margin-left: 1rem;
  background-color: #dbdbdb;
  padding: 0.25rem 0.25rem;
}

.pdfBillInfoRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-right: 1rem;

  .pdfDate {
    width: fit-content;
    margin-bottom: 0.5rem;
    background-color: #dbdbdb;
    text-align: center;
    padding: 0.125rem 0.25rem;
    margin-right: 1rem;
    font-weight: 500;
  }
  table {
    width: 40%;
    thead {
      background-color: #f5f5f5;
    }
    tbody {
      background-color: #dbdbdb;
    }
  }
  .pdfInfoRightWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.pdfthDescription {
  width: 30%;
  border-bottom: 0.25rem solid red;
  border-collapse: collapse;
}

.exportBtn {
  padding: 0.5rem;
  width: 8rem;
  color: #f5f5f5;
  background-color: rgb(143, 2, 2);
  margin: 0.5rem;
  border: none;
  cursor: pointer;
  border-radius: 0.125rem;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
